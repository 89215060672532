import { createApp } from 'vue'
import App from './App.vue'

// Firebase
import { initializeApp } from "firebase/app";


const firebaseConfig = {
  apiKey: "AIzaSyBHy-YI3r6uAVPA_ivZsMAvpheCir9LwC8",
  authDomain: "clgmbh-anlageformular.firebaseapp.com",
  projectId: "clgmbh-anlageformular",
  storageBucket: "clgmbh-anlageformular.appspot.com",
  messagingSenderId: "66278079498",
  appId: "1:66278079498:web:383ebc9713fbb1776533f3"
};

initializeApp(firebaseConfig);
createApp(App).mount('#app')
