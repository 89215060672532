<template>
  <form id="form" action="" onkeydown="return event.key != 'Enter';">
    <div>
      <div class="line" v-for="(element, index) in fields" :key="index">
        <label :for="fields[index].label"
          >{{ fields[index].label
          }}<span title="Pflichtfeld" v-if="fields[index].mandatory === true">
            *</span
          ></label
        >
        <input
          v-model="fields[index].value"
          :id="fields[index].label.toLowerCase()"
          type="text"
          autocomplete="off"
        />
      </div>

      <label for="grund-der-anschaffung"
        >Grund der Anschaffung<span title="Pflichtfeld"> *</span></label
      >
      <textarea
        v-model="constFields[0].value"
        @keydown="widenTextarea()"
        id="grund-der-anschaffung"
      ></textarea>

      <input
        type="radio"
        name="if-grund"
        value="Erweiterung"
        id="erweiterung"
        @click="radioToggle('Erweiterung')"
        v-model="constFields[3].value"
      /><label class="radio-label" for="erweiterung">Erweiterung</label> <br />
      <input
        type="radio"
        name="if-grund"
        value="Austausch"
        id="austausch"
        @click="radioToggle('Austausch')"
        v-model="constFields[3].value"
      /><label class="radio-label" for="austausch">Austausch</label>

      <label v-if="constFields[3].value !== ''" for="anlagegut"
        >Anlagegut</label
      >
      <input
        v-if="constFields[3].value !== ''"
        type="text"
        id="anlagegut"
        v-model="constFields[4].value"
      />

      <p>Antragsteller</p>
      <label for="datum">Datum<span title="Pflichtfeld"> *</span></label>
      <input v-model="constFields[1].value" id="datum" type="date" />
      <label for="unterschrift"
        >Unterschrift<span title="Pflichtfeld"> *</span></label
      >
      <div v-if="sigOupl" class="flex">
        <button @click="(uplSig = true), (sigOupl = false)">
          Unterschrift als PNG hochladen
        </button>
        <button @click="liveSig = true">Unterschrift erstellen</button>
      </div>
      <canvas
        id="signature-pad"
        class="signature-pad hidden"
        width="400"
        height="200"
      ></canvas>
      <div></div>
      <button
        style="margin-right: calc(10%)"
        class="hidden"
        id="clear"
        @click="signaturePad.clear()"
      >
        Löschen
      </button>

      <div v-if="uplSig">
        <input
          style="border: none"
          id="unterschrift"
          info="test"
          type="file"
          accept=".png"
          @input="getSignature()"
        />
        <br />
      </div>
      <button
        style="margin-left: 0"
        v-if="!sigOupl"
        @click="(liveSig = false), (uplSig = false), (sigOupl = true)"
      >
        Zurück
      </button>
    </div>
    <label for="zusätzliche-dokumente">Zusätzliche Dokumente anhängen</label>
    <div class="flex">
      <input id="zusätzliche-dokumente" type="file" multiple accept=".pdf" />
      <button
        title="Dateienauswahl entfernen"
        id="clear-button"
        @click="clearInput('zusätzliche-dokumente')"
      >
        X
      </button>
    </div>
    <button
      v-if="requirementsMet"
      class="herunterladen"
      type="submit"
      @click="createPDF()"
    >
      Herunterladen
    </button>
    <button
      v-if="!requirementsMet"
      style="font-size: 1.5rem, width:100%;"
      class="disabled herunterladen"
      type="submit"
    >
      Herunterladen
    </button>
    <img id="coml" style="display: none" src="../assets/coml.png" alt="" />
    <a class="hidden" id="link">Download</a>
  </form>
</template>

<script>
import jsPDF from "jspdf";
import SignaturePad from "signature_pad";
import { PDFDocument } from "pdf-lib";

export default {
  name: "FormInner",
  components: {},
  data() {
    return {
      fields: [],
      constFields: [],
      requirementsMet: true,
      payload: null,
      signaturePad: null,
      sigOupl: true,
      liveSig: false,
      uplSig: false,
    };
  },
  methods: {
    fillFields() {
      this.fields.push(
        { label: "Abteilung", mandatory: true, type: "text", value: "" },
        { label: "Nutzer", mandatory: true, type: "text", value: "" },
        { label: "Gerätetyp", mandatory: true, type: "text", value: "" },
        {
          label: "Anlagegegenstand",
          mandatory: true,
          type: "text",
          value: "",
        },
        { label: "Seriennummer", mandatory: true, type: "text", value: "" },
        { label: "Auftragsnummer", mandatory: true, type: "text", value: "" },
        {
          label: "Anschaffungswert",
          mandatory: true,
          type: "text",
          value: "",
        }
      );
      this.constFields.push(
        {
          label: "Grund der Anschaffung:",
          mandatory: false,
          type: "text",
          value: "",
        },
        { label: "Datum:", mandatory: true, type: "date", value: "" },
        {
          label: "Unterschrift:",
          mandatory: true,
          type: "image",
          value: null,
          height: 0,
        },
        {
          label: "Erweiterung/Austausch",
          mandatory: false,
          type: "radio",
          value: "",
        },
        {
          label: "Anlagegut",
          mandatory: false,
          type: "text",
          value: "",
        }
      );
    },
    radioToggle(radio) {
      if (radio === this.payload) this.constFields[3].value = "";
      this.payload = radio;
    },
    formComplete() {
      this.requirementsMet = true;
      for (let i = 0; i < this.fields.length; i++) {
        if (this.fields[i].mandatory === true && this.fields[i].value === "") {
          this.requirementsMet = false;
        }
      }
      if (this.constFields[0].value === "") this.requirementsMet = false;
      if (
        this.constFields[1].value === "" ||
        this.constFields[1].value.includes("undefined")
      )
        this.requirementsMet = false;
      if (this.constFields[2].value === null && this.signaturePad.isEmpty())
        this.requirementsMet = false;
    },
    async getSignature() {
      let evt = document.getElementById("unterschrift");
      var tgt = evt.target || window.event.srcElement,
        files = tgt.files;
      this.signaturePad.clear();
      // FileReader support
      if (FileReader && files && files.length) {
        var fr = new FileReader();
        var signature = new Image();
        fr.onload = function () {
          signature.src = fr.result;
        };
        fr.readAsDataURL(files[0]);
      }
      this.constFields[2].value = signature;
      this.constFields[2].height = signature;
    },
    async readFileAsync(file) {
      return new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.onload = () => {
          resolve(reader.result);
        };
        reader.onerror = reject;
        reader.readAsArrayBuffer(file);
      });
    },
    async createPDF() {
      this.payload = 1;
      let line = 10;
      let pdfName = "Antragsformular Anlagevermögen";
      var doc = new jsPDF();
      doc.setTextColor("#2c2e34");
      //Header
      doc.addImage(document.getElementById("coml"), "png", 10, line, 60, 18.5);
      doc.setFontSize(20);
      doc.setFont("Helvetica", "", "bold");

      line += 10;
      doc.text("Antragsformular Anlagevermögen", 75, line);
      doc.setFont("Helvetica", "", "normal");
      doc.setFontSize(12.5);

      //Ausgabe für fields
      line += 10;
      for (let i = 0; i < this.fields.length; i++) {
        if (this.fields[i].value !== "") {
          line += 17.5;
          doc.text(this.fields[i].label + ":", 15, line);
          doc.text(this.createPreText(this.fields[i].value), 85, line);
        }
      }

      //Ausgabe für const Fields
      if (this.constFields[0].value !== "") {
        line += 17.5;
        doc.text(this.constFields[0].label, 15, line);
        doc.text(this.createPreText(this.constFields[0].value), 85, line);
      }

      line += 15 + this.payload * 4.5;
      if (this.constFields[3].value !== "") {
        doc.text(this.constFields[3].value + ":", 15, line);
        doc.text(this.createPreText(this.constFields[4].value), 85, line);
      }

      line += 17.5;
      doc.text("Antragsteller:", 15, line);

      let imgHeight = 22.5;
      if (this.constFields[2].value) {
        imgHeight =
          (45 / (await this.constFields[2].value.width)) *
          (await this.constFields[2].value.height);
      }

      this.createDate();
      line += 10;
      doc.text(this.constFields[1].value, 15, line + imgHeight / 2);

      if (!this.signaturePad.isEmpty()) {
        let sig = this.signaturePad.toDataURL();
        doc.addImage(sig, 130, line - 2.5, 45, 20);
      } else {
        doc.addImage(this.constFields[2].value, 130, line - 7.5, 45, imgHeight);
      }
      doc.line(
        15,
        line + imgHeight / 2 + 2.5,
        60,
        line + imgHeight / 2 + 2.5,
        "S"
      );
      doc.line(
        130,
        line + imgHeight / 2 + 2.5,
        175,
        line + imgHeight / 2 + 2.5,
        "S"
      );
      doc.setFontSize(8);
      doc.text("Datum", 15, line + imgHeight / 2 + 7.5);
      doc.text("Unterschrift", 130, line + imgHeight / 2 + 7.5);

      line += imgHeight + 10;
      doc.setFontSize(12.5);
      doc.text("Genehmigung:", 15, line);

      line += 17.5;
      doc.line(15, line, 60, line, "S");
      doc.line(130, line, 175, line, "S");

      line += 5;
      doc.setFontSize(8);
      doc.text("Datum", 15, line);
      doc.text("Unterschrift", 130, line);

      //Zusätliches Dokument anfügen
      if (document.getElementById("zusätzliche-dokumente").files.length > 0) {
        let lastDoc = await PDFDocument.create();
        const inputFiles = document.getElementById(
          "zusätzliche-dokumente"
        ).files;
        let buffers = [];
        let loaded = [];

        buffers.push(doc.output("arraybuffer"));

        for (let i = 0; i < inputFiles.length; i++) {
          buffers.push(await this.readFileAsync(inputFiles[i]));
        }

        for (let i = 0; i <= inputFiles.length; i++) {
          loaded.push(await PDFDocument.load(buffers[i]));
        }

        for (let i = 0; i <= inputFiles.length; i++) {
          let pages = await lastDoc.copyPages(
            loaded[i],
            loaded[i].getPageIndices()
          );
          pages.forEach((page) => lastDoc.addPage(page));
        }

        const mergedPdfFile = await lastDoc.save();

        this.downloadMergedPDF(
          mergedPdfFile,
          pdfName + ".pdf",
          "application/pdf"
        );
      } else {
        doc.save(pdfName + ".pdf");
      }
    },
    downloadMergedPDF(file, filename, type) {
      const link = document.getElementById("link");
      link.download = filename;
      let binaryData = [];
      binaryData.push(file);
      link.href = URL.createObjectURL(new Blob(binaryData, { type: type }));
      document.getElementById("link").click();
    },
    widenTextarea() {
      const area = document.getElementById("grund-der-anschaffung");
      area.style.height = area.scrollHeight + 2 + "px";
    },
    createPreText(input) {
      if (typeof input === "string") {
        let pieces = input.split(" ");
        let newLines = [""];
        for (let i = 0; i < pieces.length; i++) {
          if (newLines[newLines.length - 1].length <= 38) {
            let test = newLines[newLines.length - 1] + pieces[i] + " ";
            if (test.length > 38) {
              newLines[newLines.length - 1] += "\n";
              this.payload += 0.5;
              newLines.push(pieces[i] + " ");
            } else {
              newLines[newLines.length - 1] += pieces[i] + " ";
            }
          } else {
            newLines[newLines.length - 1] += "\n";
            this.payload += 1;
            newLines.push(pieces[i] + " ");
          }
        }
        return newLines;
      } else {
        return;
      }
    },
    createDate() {
      if (this.constFields[1].value.includes("-")) {
        let pieces = this.constFields[1].value.split("-");
        let newDate = pieces[2] + "." + pieces[1] + "." + pieces[0];
        this.constFields[1].value = newDate;
      }
    },
    clearInput(e) {
      let input = document.getElementById(e);
      if (input.value !== "") input.value = "";
    },
  },
  beforeMount() {
    this.fillFields();
  },
  mounted() {
    window.addEventListener("mousemove", this.formComplete);

    var form = document.getElementById("form");
    function handleForm(event) {
      event.preventDefault();
    }
    form.addEventListener("submit", handleForm);

    const canvas = document.querySelector("canvas");
    if (canvas) {
      this.signaturePad = new SignaturePad(canvas, {
        minWidth: 2.5,
        maxWidth: 5,
        penColor: "rgb(0,0,0)",
      });
    }
  },
  watch: {
    liveSig() {
      const canvas = document.querySelector("canvas");
      if (this.liveSig) {
        canvas.classList.remove("hidden");
        document.getElementById("clear").classList.remove("hidden");
        this.sigOupl = false;
      } else {
        canvas.classList.add("hidden");
        document.getElementById("clear").classList.add("hidden");
      }
    },
  },
};
</script>

<style>
form {
  width: 100%;
  flex-direction: column;
  margin: 30px 0;
}
input {
  font-size: 1.1rem;
  height: 35px;
  border: none;
  border: 1px solid #ced4da;
  border-radius: 2.55px;
  width: 100%;
  margin-bottom: 30px;
}
input[type="file"] {
  border: none;
}
input[type="radio"] {
  width: 17px;
  height: 17px;
  margin-right: 20px;
}
textarea {
  padding: 5px;
  width: 75vw;
  max-width: 640px;
  min-width: 200px;
  height: 40px;
  min-height: 35px;
  font-size: 1.1rem;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  box-sizing: border-box;
  border: 1px solid #ced4da;
  border-radius: 2.55px;
  margin-bottom: 30px;
}
button {
  font-size: 1.1rem;
  padding: 10px;
  background-color: #4089c9;
  color: white;
  border: none;
  border-radius: 5px;
  width: 45%;
  margin-bottom: 30px;
}
button:hover {
  background-color: #356fa2;
  cursor: pointer;
}
button.disabled {
  background-color: #71a0c8;
}
button.disabled:hover {
  cursor: not-allowed;
}
.hidden {
  visibility: hidden;
  height: 0 !important;
  width: 0 !important;
  margin: 0;
  padding: 0;
  position: fixed;
}
.herunterladen {
  width: 101%;
}
label {
  color: #212529;
  display: block;
  margin-bottom: 10px;
}
.radio-label {
  display: inline;
}
.flex {
  display: flex;
  justify-content: space-between;
}
canvas {
  border: 1px solid #ced4da;
  border-radius: 2.55px;
  margin: 0 calc(50% - 200px) 10px;
}
#clear-button {
  width: fit-content;
  height: fit-content;
  font-size: 1.1rem;
  background-color: grey;
  padding: 5px 10px;
}
#clear-button:hover {
  background-color: rgb(117, 117, 117);
}
#clear-button:active {
  background-color: grey;
}
span {
  color: #4089c9 !important;
}
</style>