<template>
  <div id="header">
    <div class="container">
<img src="./assets/color.png" alt="Comline">
      Antragsformular Anlagevermögen
    </div>
  </div>
  <div id="bucket">
  <form-inner class="container"></form-inner>
  </div>
</template>

<script>
import FormInner from "./components/FormInner.vue";

export default {
  name: "App",
  components: {
    FormInner,
  },
};
</script>

<style>
body {
  margin: 0;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
    overflow-y: scroll;
}
#header {
  background-color: #4089c9;
  color: white;
  padding: 20px 25px;
  height: 25px;
  font-size: 1.5rem;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.container {
  width: 75vw;
  max-width: 633px;
  min-width: 200px;
}
#bucket {
  width: calc(100vw - 15px);
  display: flex;
  justify-content: center;
}
img {
  height: 2rem;
  border-radius: 2.5px;
  position: relative;
  top: 5px;
}
</style>
